var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('PageNavigation',{attrs:{"items":_vm.bread}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-material-card',{attrs:{"title":_vm.$vuetify.lang.t('$vuetify.quertClass'),"icon":"mdi-apple-finder"}},[_c('v-sheet',{staticClass:"my-4"},[_c('v-toolbar-items',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.Categories2'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"green","elevation":"2"},on:{"click":_vm.handleDialog}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.createCategory")))],1)],1)],1),_c('v-divider',{staticStyle:{"border-color":"#ccc"}}),_c('v-treeview',{attrs:{"items":_vm.items,"hoverable":"","item-children":"items","transition":""},on:{"handleDialog":_vm.handleDialog,"handleDeleteDialog":_vm.handleDeleteDialog},scopedSlots:_vm._u([{key:"append",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"blue"},on:{"click":function($event){return _vm.handleDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.edit")))])])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){return _vm.handleDeleteDialog(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$vuetify.lang.t("$vuetify.delete")))])])],1)]}}])})],1),_c('QuestionCategoryCRUD',{attrs:{"dialog":_vm.dialog,"myObj":_vm.obj},on:{"update:dialog":function($event){_vm.dialog=$event},"handleData":_vm.handleQuestion}}),_c('DialogDelete',{attrs:{"dialogDelete":_vm.dialogDelete,"deleteObj":_vm.deleteObj},on:{"update:dialogDelete":function($event){_vm.dialogDelete=$event},"update:dialog-delete":function($event){_vm.dialogDelete=$event},"handleDelete":_vm.handleDeleteConfirm}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }