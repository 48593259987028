<template>
  <v-container fluid>
    <PageNavigation :items="bread" />

    <v-row>
      <v-col cols="12">
        <base-material-card
          :title="$vuetify.lang.t('$vuetify.quertClass')"
          icon="mdi-apple-finder"
        >
          <v-sheet class="my-4">
            <v-toolbar-items class="d-flex align-center">
              {{ $vuetify.lang.t('$vuetify.Categories2') }}
              <v-spacer />
              <v-btn color="green" elevation="2" @click="handleDialog"
                ><v-icon left>mdi-plus</v-icon
                >{{ $vuetify.lang.t("$vuetify.createCategory") }}</v-btn
              >
            </v-toolbar-items>
          </v-sheet>

            <v-divider style="border-color: #ccc;"></v-divider>

            <v-treeview 
              :items="items" 
              hoverable
              item-children="items"
              transition
              v-on:handleDialog="handleDialog"
              v-on:handleDeleteDialog="handleDeleteDialog"
            >
            
            <template slot="append" slot-scope="{ item }">
              <!-- <v-list-item-action class="mr-5">
                <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-0"
                      color="green"
                      @click="handleDialog(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-plus
                    </v-icon>
                  </template>
                  <span>{{ $vuetify.lang.t("$vuetify.add") }}</span>
                </v-tooltip>
              </v-list-item-action> -->
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="mr-2"
                      color="blue"
                      @click="handleDialog(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-pencil
                    </v-icon>
                  </template>
                  <span>{{ $vuetify.lang.t("$vuetify.edit") }}</span>
                </v-tooltip>
              </v-list-item-action>
              <v-list-item-action>
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="handleDeleteDialog(item)"
                      color="red"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>{{ $vuetify.lang.t("$vuetify.delete") }}</span>
                </v-tooltip>
              </v-list-item-action>
            </template>

            </v-treeview>

        </base-material-card>

        <QuestionCategoryCRUD
          :dialog.sync="dialog"
          :myObj="obj"
          @handleData="handleQuestion"
        />

        <DialogDelete
          :dialogDelete.sync="dialogDelete"
          :deleteObj="deleteObj"
          @handleDelete="handleDeleteConfirm"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { quertTypeList, upsertQuertTypeList, delQuertTypeList, QuertList } from '@/api/account'

export default {
  name: "question",
  components: {
    tableImg: () => import("@/components/tableImg"),
    QuestionCategoryCRUD: () => import("@/components/customerService/questionCategoryCRUD"),
    DialogDelete: () => import("@/components/deleteDialog"),
    PageNavigation: () => import("@/components/pageNavigation"),
  },
  data() {
    return {
      dialog: false,
      dialogDelete: false,
      bread: [
        { text: this.$vuetify.lang.t("$vuetify.home") },
        { text: this.$vuetify.lang.t("$vuetify.user") },
        { text: this.$vuetify.lang.t("$vuetify.quertClass") },
      ],
      statuses: ["Pending", "To be received", "off the stocks", "All"],
      warehouses: ["All", "A9"],
      items: [],
      search: "",
      obj: {
        "id": "",
        "parent": "",
        "tree_code": "",
        "name": "",
        "note": "",
        "order": 0,
        "items": [],
      },
      deleteObj: {
        "id": "",
        "parent": "",
        "tree_code": "",
        "name": "",
        "note": "",
        "order": 0,
        "items": [],
      },
    };
  },
  methods: {
    getData() {
      quertTypeList()
        .then((data) => {
          this.items = [...data.items];

        })
        .catch((err) => console.log(err));
    },
    handleDeleteDialog(obj) {
      this.dialogDelete = true;
      this.deleteObj = obj;
    },
    handleDeleteConfirm() {
      this.dialogDelete = false;
      console.log(this.deleteObj);
    },
    handleDialog(obj) {
      this.dialog = true;
      this.obj = obj;
    },
    handleQuestion(obj) {
      this.dialog = false;
      upsertQuertTypeList(obj).then(res => {
        console.log(res)
        this.getData()
      })
    },
  },
  mounted() {
    this.getData();
  },
};
</script>